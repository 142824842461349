import React from "react"

const Angebot = () => (
  <div className="artikel angebot">
    <div className="angebot-artikel">
      <h2>stimm-arbeit</h2>
      <p>
        wirkt durch die innere Berührung der Stimme und den Atem. Dies führt zum
        persönlichen Ausdruck und bringt uns in Beziehung.
      </p>
    </div>
    <div className="angebot-artikel">
      <h2>polarity therapie</h2>
      <p>
        bewirkt dies mit den Händen in unterschiedlichen Berührungsqualitäten
        und durch das Verbinden sich entsprechender Körperpolaritäten.
      </p>
    </div>
    <div className="angebot-artikel">
      <h2>somatic experiencing SE</h2>
      <p>
        arbeitet mit bewusster Wahrnehmung von Körperempfindungen, inneren
        Bildern, Bewegungsimpulsen, Emotionen und Gedanken. Die vom autonomen
        Nervensystem gesteuerten Erregungszustände werden allmählich wieder in
        eine gesunde Selbstregulation geführt.
      </p>
    </div>
    <div className="angebot-artikel">
      <p>
        Mein Vorgehen ergibt sich aus dem Moment in der Begegnung. Mit Freude
        schöpfe ich aus der Quelle meiner vielfältigen Erfahrungen im Begleiten
        von Menschen über Jahrzehnte.
      </p>
    </div>
  </div>
)

export default Angebot
