import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import imagePfeil from "./../images/PfeilAufzaehlung.svg"

const steckbrief = [
  "Mutter von 2 erwachsenen Töchtern",
  "Somatic Experiencing (SE) Practitioner®- Traumatherapie",
  "Polarity Körpertherapeutin",
  "KomplementärTherapeutin mit Branchenzertifikat OdA KT",
  "Kursleiterin für Körpermusik, www.koerpermusik.ch",
  "Weiterbildung Embodied VoiceWork®",
  "TaKeTiNa® -Rhythmuspädagogin",
  "Potentialorientierte Psychotherapie, ZIST",
  "Heilpädagogin",
  "Mitglied PoVS und SE-CH",
  "EMR und EGK anerkannt",
]

const Person = () => {
  return (
    <div className="artikel person">
      <div className="foto">
        <StaticImage
          src="../images/karin-enz-gerber.png"
          alt="Foto von Karin Enz Gerber"
          placeholder="blurred"
          layout="fixed"
          width={410}
        />
      </div>
      <div className="steckbrief">
        <h2>Karin Enz Gerber *1964</h2>
        <ul>
          {steckbrief.map((eintrag, index) => (
            <li key={index}>
              <img
                className="steckbrief-icon"
                src={imagePfeil}
                alt="Aufzählungszeichen Pfeil"
              />
              {eintrag}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Person
