import React from "react"

// Komponenten
import Seo from "../components/seo"
import Header from "../components/header"
import Zitat from "../components/zitat"
import Arbeit from "../components/arbeit"
import Angebot from "../components/angebot"
import Philosophie from "../components/philosophie"
import Person from "../components/person"
import Footer from "../components/footer"

// Stylesheet
import "../sass/main.scss"

// Hintergrundbilder
import logoElementLinks from "./../images/LogoelementLinks.svg"
import logoElementRechts from "./../images/LogoelementRechts.svg"

const IndexPage = () => (
  <>
    <div
      className="content"
      style={{
        "--background-image-left": `url(${logoElementLinks})`,
        "--background-image-right": `url(${logoElementRechts})`,
      }}
    >
      <Header />
      <Seo />
      <Zitat />
      <Arbeit />
      <Angebot />
      <Philosophie />
      <Person />
    </div>
    <Footer />
  </>
)

export default IndexPage
