import React from "react"

const Arbeit = () => (
  <div className="artikel arbeit">
    <p>
      Meine Arbeit ist geprägt von einer{" "}
      <span className="text-betont">ganzheitlichen Sichtweise</span>, in der
      Körper, Geist und Seele eine Einheit bilden. Als Grundlage dient das
      Wissen um eine alles bestimmende Lebensenergie, sowie die Tatsache, dass
      diese zwischen bestimmten Polen im Körper zirkuliert und sich daraus
      «energetische Landschaften» ablesen lassen.
    </p>
    <p>
      Solange die Energie frei fliessen kann, fühlen wir uns{" "}
      <span className="text-betont">gesund und in Balance</span>. Im Laufe
      unseres Lebens können diese Energien durch frühe Prägungen und Traumata –
      Unfälle, Operationen, Krankheiten, Verluste und andere schwere seelische
      Belastungen – blockiert werden. Verspannungen, Schmerzen, Erschöpfung,
      Rücken- und Nackenprobleme, Schlaf- und Verdauungs­störungen bis hin zu
      komplexen Krankheitsbildern sind häufige Folgesymptome.
    </p>
  </div>
)

export default Arbeit
