import React from "react"
import Adresse from "./adresse"

const Footer = () => {
  return (
    <footer>
      <Adresse />
      <div className="author">
        <p>
          <a href="/datenschutz">Datenschutzerklärung</a>
        </p>
        <p className="links-trenner">|</p>
        <p>
          <a target="_blank" href="https://www.tom-ifd.ch" rel="noreferrer">
            Webdesign: tom-ifd.ch
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
