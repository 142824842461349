import React from "react"
import imgLogo from "../images/Logo.svg"

const Header = () => (
  <header>
    <a href="/">
      <img
        className="seiten-logo"
        src={imgLogo}
        alt="Logo von Karin Enz Gerber"
      />
    </a>
  </header>
)

export default Header
