import React from "react"

const Zitat = () => (
  <div className="artikel zitat">
    <p className="zitat__text">
      «Energie ist die wahre Substanz hinter <br /> dem Erscheinungsbild von
      Materie und Gestalt»
    </p>
    <p className="zitat__quelle">
      Dr. Randolphe Stone –{" "}
      <span className="zitat__quelle-zweitezeile">
        Begründer Polarity Therapie
      </span>
    </p>
  </div>
)

export default Zitat
