import React from "react"

const Philosophie = () => (
  <div className="artikel philosophie">
    Ich schaffe einen Raum von
    <span className="text-betont"> Sicherheit, Präsenz und Kontakt</span> und
    verstehe mich als unterstützende Begleiterin auf dem Weg, durch gemeinsames
    Hinhorchen auf die Sprache des Körpers, Empfindungen und Emotionen ins
    Bewusstsein kommen zu lassen. So kann sich gehaltene Energie durch Ausloten
    der inneren Zustände allmählich lösen, wieder ins Fliessen kommen und die
    Person{" "}
    <span className="text-betont">
      zu einem ausbalancierten Zustand zurückfinden
    </span>
    . Wohlbefinden und Lebendigkeit, Kraft und Offenheit, Klarheit und
    Entspannt-Sein entfalten sich neu.
  </div>
)

export default Philosophie
