import React from "react"
import MailIcon from "./../images/Mail.svg"
import MailIconHover from "./../images/MailWeiss.svg"
import TelefonIcon from "./../images/Telefon.svg"
import TelefonIconHover from "./../images/TelefonWeiss.svg"
import AnfahrtIcon from "./../images/Anfahrt.svg"
import AnfahrtIconHover from "./../images/AnfahrtWeiss.svg"

const Adresse = () => (
  <div className="adresse">
    <div className="adresse-post">
      <p>
        <span className="adresse-erstezeile">Karin Enz Gerber</span>
        <span className="adresse-komma">,</span> Poststrasse 2, 4410 Liestal
      </p>
    </div>
    <div className="adresse-kontakt">
      <div
        className="kontakt-daten kontakt-daten--anfahrt"
        style={{
          "--adresse-icon-anfahrt": `url(${AnfahrtIcon})`,
          "--adresse-icon-anfahrt-hover": `url(${AnfahrtIconHover})`,
        }}
      >
        <a
          href="https://www.google.ch/maps/place/Kulturhaus+Palazzo/@47.484071,7.732342,17z/data=!4m12!1m6!3m5!1s0x4791ca6a6d59e14d:0x9fe3d836fb62f63b!2sKulturhaus+Palazzo!8m2!3d47.4840036!4d7.7322804!3m4!1s0x4791ca6a6d59e14d:0x9fe3d836fb62f63b!8m2!3d47.4840036!4d7.7322804"
          target="_blank"
          rel="noreferrer"
          className="kontakt-daten__text"
        >
          Anfahrt
        </a>
      </div>
      <div
        className="kontakt-daten kontakt-daten--telefon"
        style={{
          "--adresse-icon-telefon": `url(${TelefonIcon})`,
          "--adresse-icon-telefon-hover": `url(${TelefonIconHover})`,
        }}
      >
        <a href="tel:+41793320944" className="kontakt-daten__text">
          +41 79 332 09 44
        </a>
      </div>
      <div
        className="kontakt-daten kontakt-daten--email"
        style={{
          "--adresse-icon-mail": `url(${MailIcon})`,
          "--adresse-icon-mail-hover": `url(${MailIconHover})`,
        }}
      >
        <a href="mailto:karin-enz@bluemail.ch" className="kontakt-daten__text">
          karin-enz@bluemail.ch
        </a>
      </div>
    </div>
  </div>
)

export default Adresse
